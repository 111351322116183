.root {
  flex-grow: 1;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.canvas {
  flex-grow: 1;
  height: inherit;
  position: relative;
  overflow: hidden;
}

/* notes: how to do node selected selectable focus */
.canvas *[class*='react-flow__node'][class*='selected'],
.canvas *[class*='react-flow__node'][class*='selectable']:focus {
  /* outline: 2px solid blue; */
  /* background-color: lightblue; */
}
