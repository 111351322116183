button.root {
  width: 36px;
  height: 36px;
  border-radius: 4px;

  border: 1px solid var(--Grey-400, #e3e4e6);
  background: var(--Grey-100, #fff);
}

button.root:hover {
  background: var(--Primary-100, #f3eefc);
}
button.root:hover > svg {
  color: var(--Primary-500, #2b63e6);
}

button.root > svg {
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
}
