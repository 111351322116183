.root {
  height: 32px;
  padding: 6px 8px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid var(--Grey-400, #e3e4e6);
  background: var(--Grey-100, #fff);
}
.root:hover,
.root:focus-visible {
  border: 1px solid var(--Grey-500, #939aa9);
  background: var(--Grey-200, #f8f9f9);
}
.root:active,
.root[class*='active'] {
  border: 1px solid var(--Grey-400, #e3e4e6);
  background: var(--Primary-100, #eef3fd);
}

.root[disabled] {
  cursor: not-allowed;
  border: 1px solid var(--Grey-400, #e3e4e6);
  background: var(--Grey-100, #fff);
}

.label {
  color: var(--Grey-900);
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* line-height: 130%; 15.6px */
  letter-spacing: -0.12px;
}

.root[disabled].label {
  color: var(--Grey-500, #939aa9);
}
