.dropdown {
  transform: translateX(-8px);
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content > span {
  color: black;
}
.dropdownContent {
  width: 285px;
  right: 0;
}

.list {
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(7, 32px);
  grid-template-rows: 32px;

  overflow-y: scroll;
  height: 350px;
}

.defaultBox {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 42px;
}
.defaultIcon {
  display: grid;
  place-items: center;
  padding: 4px;
  border-radius: 4px;
}

.buttonColorBox {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, 16px);
  grid-template-rows: 16px;
  align-items: center;
  padding: 16px;
}

.buttonColor {
  background-color: transparent;
  border-radius: 50%;
  padding: 0px;
  margin: 0;
  display: grid;
  place-items: center;
  width: 18px;
  height: 18px;
  border: 2px solid transparent;
}
.buttonColor[class*='active'],
.buttonColor:hover,
.buttonColor:focus-visible {
  border-color: var(--Grey-400, #e3e4e6);
}

.buttonColor > div {
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.buttonColor[class*='active'] > div,
.buttonColor:hover > div,
.buttonColor:focus-visible > div {
  width: 12px;
  height: 12px;
}

.noResult {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.noResult[class*='hidden'] {
  display: none;
}

.dropdownHeader {
  display: flex;
  gap: 8px;
}
.headerButton {
  width: 100%;
}

.dropdownBody {
  /* display: grid;
  gap: 8px;
  grid-template-columns: 100% 100%;
  grid-template-rows: 1fr;
  transition: all 0.3s ease-in-out;
  padding: 8px; */
  min-height: 0;
  width: 200%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  transition: all 0.25s linear;
}

.listWrapper {
  /* display: block; */
  /* padding: 8px; */
  min-height: 0;
  height: 100%;
  overflow: auto;
  display: grid;
  align-content: start;
  grid-gap: 2px;
  gap: 2px;
  padding: 2px;
}
