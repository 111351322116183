.root {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 12px;
}

.root:before,
.root:after {
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--Grey-300, #f3f3f4);
  min-width: 15px;
}

.content {
  display: flex;
  align-items: center;
  text-align: center;
}
