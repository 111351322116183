.root {
  position: absolute;
  /* min-width: 200px; */
  right: 0;
  top: 0;
  background: var(--Grey-200, #f8f9f9);
  height: calc(100%);
  transform: translateX(100%);

  transition: all 0.5s ease;
}

.root[class*='open'] {
  transform: translateX(0);
}

.content {
  /* padding: 10px; */
  max-width: 300px;
}

.button {
  display: none;
  position: absolute;
  left: 0;
  top: 20px;
  transform: translateX(-90%);
}

.root[class*='open'] .button {
  display: flex;
}
