button.root {
  border: none;
  padding: 2px;
  /* width: 24px; */
  /* height: 24px; */
}

button.root:hover,
button.root:focus-visible {
  border: none;
  color: var(--Primary-500, #2b63e6);
}
