.root {
  display: flex;
  gap: 8px;
  align-items: center;
  /* justify-content: ; */
  border-bottom: 1px solid var(--Grey-300, #f3f3f4);
  background: var(--Grey-100, #fff);
  padding: 24px;
}

.iconBox {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.input {
  outline: none;
  border-radius: 4px;
  border: 1px solid var(--Grey-400, #e3e4e6);
  background: var(--Grey-100, #fff);
  font-size: 16px;
  font-weight: 500;
  color: var(--Grey-800, #333);
}

.input:focus {
  border-radius: 4px;
  border: 1px solid var(--Primary-500, #8750e1);
  background: var(--Grey-100, #fff);
  box-shadow: 0px 0px 4px 0px rgba(43, 99, 230, 0.48);
}

.dropdownButton {
  display: flex;
}
