.root {
  display: flex;
  justify-content: space-between;
  padding: 12px 40px;
  border-bottom: 2px solid var(--Grey-400, #f3f3f4);
}

.left {
  display: flex;
  gap: 10px;
}

.right {
  display: flex;
  gap: 10px;
}
