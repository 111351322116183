.root {
  position: absolute;
  padding: 8px;
  padding-right: 34px;
  background: var(--Grey-100);
  display: flex;

  gap: 4px;
  border-radius: 4px;
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--Grey-300);
}

.buttonClosePadding {
  padding-right: 8px;
}

.buttonClose {
  position: absolute;

  right: 6px;
  top: 6px;
}
