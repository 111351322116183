.textarea {
  /* outline: none; */
  border: none;
  width: 100%;
  height: 100%;
  background-color: var(--Charts-Yellow-500, #fdd655);
  padding: 12px;
  margin: 0;

  font-family: 'SF Pro Text', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  resize: none;
}

.textarea::placeholder {
  color: #ac8d26;
}
