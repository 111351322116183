:root {
  --Grey-100: #ffffff;
  --Grey-150: #fbfbfb;
  --Grey-200: #f8f9f9;
  --Grey-300: #f3f3f4;
  --Grey-400: #e3e4e6;
  --Grey-500: #939aa9;
  --Grey-600: #686d78;
  --Grey-700: #3f434d;
  --Grey-800: #2b2f39;
  --Grey-900: #1a1d23;

  --Primary-100: #eef3fd;
  --Primary-400: #91aef2;
  --Primary-500: #2b63e6;
  --Primary-600: #174cc6;

  --System-Success-100: #e9f5f1;
  --System-Success-400: #b3dfcf;
  --System-Success-500: #4cbf96;
  --System-Success-600: #099864;
  --System-Warning-100: #fbeaee;
  --System-Warning-400: #f7b4c6;
  --System-Warning-500: #ef6389;
  --System-Warning-600: #ea0b47;

  --Charts-Purle-100: #f3f2fc;
  --Charts-Purle-400: #b7aded;
  --Charts-Purle-500: #8979e6;
  --Charts-Orange-100: #fdf5ed;
  --Charts-Orange-400: #f5c190;
  --Charts-Orange-500: #f5b070;
  --Charts-Yellow-400: #fdeb83;
  --Charts-Yellow-500: #fdd655;
}
