.title {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  margin: 0;
  margin-top: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.root:hover .title {
  display: block;
}

.root p.title[class*='always'] {
  display: block;
}

.settings {
  display: none;
  position: absolute;
  top: -12px;
  right: -12px;
  transform: scale(0.8);
}
.root:hover .settings {
  display: block;
}

.handle {
  width: 8px;
  height: 8px;
  background: #fff;
  border: 1px solid #2b63e6;
}
