.root {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 20px));
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--Grey-300, #f3f3f4);
  background: var(--Grey-100, #fff);
}

.buttonClose {
  position: absolute;
  top: 0;
  right: -8px;
  transform: translateX(calc(100%));
}
