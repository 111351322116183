.dropdown {
  position: relative;
  border-radius: 4px;
}
.label {
  margin-bottom: 2px;
}

.content {
  z-index: 30;
  position: absolute;

  background: var(--Grey-100, #fff);
  cursor: default;
  overflow: hidden;
  width: max-content;
}

.border {
  border-radius: 4px;
  border: 1px solid var(--Grey-400, #e3e4e6);
}

.container {
  padding: 4px;
}
.container > :not(:last-child) {
  margin-bottom: 4px;
}
