.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  align-items: center;
  justify-items: center;
}

.icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.page {
  /* it does not work */
  /* transform: scale(1.2); */
}

.title {
  white-space: nowrap;
  margin: 0;
  color: var(--Grey-900, #1a1d23);
  /* Body/Body Small */
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
}
