.root {
  position: absolute;
  pointer-events: all;

  display: grid;
  gap: 4px;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  min-width: 150px;
  padding: 4px 8px;
  border-radius: 40px;
  border: 1px solid var(--Grey-400, #e3e4e6);
  background: #fff;

  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.05);
}

.icon {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--Grey-300, #f3f3f4);
}
.svg {
  transform: scale(0.75);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.number {
  color: var(--Primary-500, #8750e1);
  font-weight: 600;
  line-height: 130%; /* 15.6px */
}

.text {
  color: var(--Grey-500, #939aa9);
  line-height: 130%; /* 15.6px */
}

.dropdown > button {
  padding: 0px 6px;
  border: 1px solid transparent;
}
