.root {
  display: flex;
  gap: 8px;
  padding: 4px;
  border: 1px solid var(--Grey-400);
  border-radius: 8px;
  background-color: white;
}

.root > button {
  border: 1px solid var(--Grey-400);
  border-radius: 8px;
  color: black;
}
